<template>
    <div class="relative gm-padding-y">
        <div class="container py-12 text-center">

            <h3 class="!leading-[1.5] font-bold mb-6">
                Instantly connect Greetmate with
                <span class="text-callout-primary">1000+ Apps and Services</span>
                with Native Integrations, Zapier and Webhooks.
            </h3>


            <Vue3Marquee :duration="20" gradient>
                <div v-for="integration in integrations" :key="integration.name"
                     class="inline-flex gap-2 items-center bg-gray-50 p-3 rounded-lg mr-4">

                        <img :src="integration.logo"
                             v-if="!integration.is_component"
                             alt="logo" class="h-6 lg:h-6 rounded-lg grayscale"/>
                        <component :is="integration.logo"
                                   v-else
                                   class="h-6 lg:h-6 rounded-lg grayscale"/>

                    <span class="lg:text-lg">{{ integration.name }}</span>
                </div>
            </Vue3Marquee>
        </div>


    </div>
</template>
<script setup lang="ts">
import mailchimp from '@/../images/logos/mailchimp.jpg?format=webp&w=500'
import asana from '@/../images/logos/asana.svg'
import slack from '@/../images/logos/slack.png?format=webp&w=250'
import zapier from '@/../images/logos/zapier.svg'
import hubspot from '@/../images/logos/hubspot.svg'
import trello from '@/../images/logos/trello.svg'
import gmail from '@/../images/logos/gmail.svg'
import googlecalendar from '@/../images/logos/googlecalendar.svg'
import googlesheets from '@/../images/logos/googlesheets.svg'
import airtable from '@/../images/logos/airtable.svg'
import ghl from '@/../images/logos/ghl.png?formate=webp&w=500'
import LazyRendering from "@/Components/LazyRendering.vue";
import {Vue3Marquee} from 'vue3-marquee'
import CalComIcon from "@/Components/Icons/CalComIcon.vue";

const integrations = [
    {
        logo: mailchimp,
    },
    {
        logo: asana,
    },
    {
        logo: slack,
    },
    {
        logo: CalComIcon,
        name: '',
        is_component: true
    },
    {
        logo: zapier,
    },
    {
        logo: hubspot,
    },
    {
        logo: ghl,
    },
    {
        logo: trello,
    },
    {
        logo: gmail,
        name: 'Google Mail'
    },
    {
        logo: googlecalendar,
        name: 'Google Calendar'
    },
    {
        logo: googlesheets,
        name: 'Google Sheets'
    },
    {
        logo: airtable,
    },

]
</script>
